import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Flow is a type of deliverables it has a purpose to visualize the way the user interacts with the interface in a glanceable way. They are hybrid between traditional flow charts with some visual interfaces included in them. To create flow, we used some annotation for help all team more easy to read and understand the flow. Some of the annotation includes.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-10.svg",
      "alt": null
    }}></img>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-9.svg",
      "alt": null
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Page Information State`}</strong>{` : Name and summary about state or screen.`}</li>
      <li parentName="ol"><strong parentName="li">{`Technical Consideration`}</strong>{` : Detail interaction and technical consideration in state or screen.`}</li>
      <li parentName="ol"><strong parentName="li">{`Page Negative State`}</strong>{` : Information detail about negative case of state or screen.`}</li>
      <li parentName="ol"><strong parentName="li">{`Solid Arrow`}</strong>{` : Line that navigate between interaction in call to action or components to other screens so that it becomes flow.`}</li>
      <li parentName="ol"><strong parentName="li">{`Diamond & confirmation `}</strong>{` : The diamond is decision node of interaction and the confirmation is option of determines user flow.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage Annotation`}</h2>
    <p>{`In making a flow you are advised to use annotations or pluggin recommendations that can help you in making a clearer screen flow for all product teams, for more details please follow the steps below.`}</p>
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-12.svg",
          "alt": "usage annotation"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`1. Turn on Helper Component Kit`}</p>{`
    `}<p parentName="div">{`
      After you have several screens that have not been connected, then please open `}<strong parentName="p">{`team library`}</strong>{` modals
      from tab `}<strong parentName="p">{`assets`}</strong>{`, serach the `}<strong parentName="p">{`Helper Component Kit`}</strong>{` from Legion Design Library,
      then switch the toogle to turn on the library.
    `}</p>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-13.svg",
          "alt": "guideline usage annotation"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`2. Start usage annotation`}</p>{`
    `}<p parentName="div">{`
      After activating the helper component library, please select the flow start screen and add information about the
      screen by dragging the `}<strong parentName="p">{`Flow / Annotation`}</strong>{` component, then from the flow initial screen select the
      cta associated with the next screen and connect using the `}<strong parentName="p">{`Flow / Arrow`}</strong>{` component or
      `}<a parentName="p" {...{
            "href": "/design/guideline/create-flow#pluggin-recomendation"
          }}>{`Autoflow`}</a>{` pluggin.
    `}</p>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-14.svg",
          "alt": "guideline usage annotation"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`3. Completed your screen flow`}</p>{`
    `}<p parentName="div">{`
      When several screens have been connected, please complete also with possibilities such as branching flow where you
      can use the `}<strong parentName="p">{`Flow / Diamond`}</strong>{` and `}<strong parentName="p">{`Flow / Confirmation`}</strong>{` components, then for detailed
      error state information using the variant `}<strong parentName="p">{`Annotation / Negative State`}</strong>{` component and for
      interaction or technical details using the variant `}<strong parentName="p">{`Annotation / Technical`}</strong>{`.
    `}</p>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Pluggin Recomendation`}</h2>
    <p>{`Here are some pluggins that we recommend you use to simplify your workflow, task, in craft the screen flow, information archithecture, wireframe, etc.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/733902567457592893"
        }}><strong parentName="a">{`Autoflow`}</strong></a>{` : Create a arrow flow between 2 objects / screen with this simple selected only`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/1004954729018393690"
        }}><strong parentName="a">{`Visual Sitemap`}</strong></a>{` : Easy-to-use text editor to design custom tree diagrams and sitemaps`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/1105916617805028875"
        }}><strong parentName="a">{`Grayscale`}</strong></a>{` : Convert your designs to grayscale to get more targeted feedback during user testing`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      